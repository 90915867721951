<script setup lang="ts">
import { useHead } from '#app';
import { getRewardfulScripts } from '~/utils/rewardful-scripts';
import LoginForm from '~/pages/login/components/login-form.vue';

useHead({
  title: 'Login | WeEngrave',
  script: [
    ...getRewardfulScripts(),
  ],
});
</script>

<template>
  <div class="container mx-auto h-100dvh flex flex-col justify-center items-center py-28">
    <LoginForm />
  </div>
</template>
